$iebar-bg: #ffc410;
$iebar-border: #eab105;
$iebar-txt-top: #755603;
$iebar-txt-bottom: #4c3906;
$iebar-txt-bottom-hover: #222;

.ie-bar {
  width: 100%;
  padding: 1rem 0.9375rem;
  text-align: center;
  border-bottom: 5px solid $iebar-border;
  background: $iebar-bg;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
  font-family: Tahoma, Verdana, Segoe, sans-serif;

  p {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $iebar-txt-top;
    font-weight: 700;
    @include font-size(0.75, 1.125);

    a {
      display: block;
      color: $iebar-txt-bottom;

      &:hover {
        color: $iebar-txt-bottom-hover;
      }
    }
  }
}
