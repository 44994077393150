.sidebar {
  position: relative;

  .no-content {
    display: none;
  }

  ul {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    font-family: $proxima;

    li {
      margin-bottom: 20px;
      letter-spacing: 1px;

      a.active {
        color: $dovegray2;
        position: static;
        font-weight: 700;

        @media (min-width: 1180px) {
          position: relative;
        }

        &:before {
          position: absolute;
          top: -2px;
          left: 0;
          width: 14px;
          height: 2px;
          border-right: 2px solid #fff;
          background: $silver;
          content: ' ';

          @media (min-width: 1180px) {
            top: 0;
            left: -32px;
            width: 2px;
            height: 16px;
            border-right: 0;
            border-bottom: 2px solid #fff;
            border-top: 2px solid #fff;
          }
        }
      }
    }
  }
}
