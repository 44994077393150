.homepage .image-navigation .image-nav-link {
  height: 350px;
  max-height: none;
}

.image-navigation {
  margin: 0 -25px;
  width: 100vw;

  @media screen and (min-width: $large) {
    display: flex;
    margin: 0;
  }

  .image-nav-link {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 1px;
    max-height: 145px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .55);
    }

    @media (min-width: $medium) {
      display: inline-block;
      width: calc(50% - 3px);
    }

    @media screen and (min-width: $large) {
      margin-right: 1px;
      width: 25%;

      &:last-of-type {
        margin-right: -4px;
      }
    }

    img {
      min-width: 100%;
      position: absolute;
      height: 100%;
      left: 0;
      object-fit: cover;
      top: 0;
    }
  }

  .nav-text {
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 50%;
    padding-bottom: 5px;
    border-bottom: 2px solid $yellowsea;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5em;
    letter-spacing: 1.5px;
    font-size: 24px;
    font-family: $lexiaadvert;
    transform: translateX(-50%) translateY(-50%);
  }
}

.subpage .image-navigation {
  @media (min-width: $large) {
    overflow: hidden;
    margin-bottom: 1px;
    max-height: 145px;
  }

  .image-nav-link {
    overflow: hidden;
    margin-bottom: 1px;
    max-height: 145px;

    @media (min-width: $large) {
      overflow: visible;
      margin-right: 1px;
      margin-bottom: 0;
      max-height: none;
    }

    img {
      transform: translateY(-25%);
    }
  }
}
