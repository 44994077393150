.fat-footer {
  margin: 0 -25px;
  margin-top: 1px;
  padding: 50px 25px 95px;
  border-top: 5px solid $alto;
  background: $alabaster;

  @media (min-width: $large) {
    margin: 0;
    padding: 50px 55px 50px 62px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &.footer-nav {
      padding: 7px 30px 13px 30px;

      @media (min-width: $large) {
        float: left;
        width: 35%;
      }

      li {
        margin-bottom: 20px;

        a {
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .locations {
    margin-top: 65px;

    @media (min-width: $large) {
      display: flex;
      margin-top: 0;
      width: 65%;
      justify-content: space-between;
    }

    li {
      margin: 25px 0;

      @media (min-width: $large) {
        margin: 0;
      }

      h3 {
        position: relative;
        margin: 0;
        margin-left: -32px;
        padding-left: 30px;
        border-left: 2px solid $silver;
        color: $tundora;
        font-size: 16px;

        &:after {
          position: absolute;
          bottom: 0;
          left: -2px;
          width: 2px;
          height: 2px;
          background: $alabaster;
          content: '';
        }
      }
    }

    address {
      padding-left: 30px;
      border-left: 2px solid $alto2;
      color: $dovegray;
      font-style: normal;
      font-size: 16px;
      line-height: 1.25em;

      .contact {
        margin: 15px 0;

        .label {
          font-weight: 600;
        }
      }

      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.thin-footer {
  margin: 0 -25px;
  padding: 30px 25px 50px;
  background: $scorpion;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 100;
  font-size: 14px;
  line-height: 1.5em;

  @media (min-width: $large) {
    display: flex;
    margin: 0;
    padding: 30px 60px 20px;
    justify-content: space-between;
  }

  ul.social-media {
    a {
      color: #ffffff;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
