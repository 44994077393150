html,
body {
    font-weight: 500;
    font-family: $proxima;
}

a {
    color: $teal;
    text-decoration: none;

    &:hover {
        color: $yellowsea;
        text-decoration: underline;
    }

    &:visited {
        // color: palette(base, secondary);
        color: $teal;
    }
}

em {
    font-style: italic;
}

.btn.link-btn {
    text-transform: uppercase;
    font-weight: 700;
    font-family: $proxima;
}

.homepage {
    h2 {
        position: relative;
        margin-bottom: 45px;
        font-size: 28px;
        font-family: $lexiaadvert;
        line-height: 1.35em;
    }

    p {
        font-size: 14px;

        @media (min-width: $large) {
            font-size: 18px;
        }
    }
}

.callout-text {
    color: $teal;
}

.all-caps {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5em;
}

.subpage .main {

    h2,
    h3,
    h4 {
        margin-bottom: 20px;
    }

    h2 {
        font-weight: 800;
        font-size: 24px;
        font-family: $proxima;
    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        font-family: $proxima;
    }

    h4 {
        font-weight: 600;
        font-size: 18px;
        font-family: $proxima;
    }

    h5 {
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 16px;
        font-family: $lexia;
    }

    h6 {
        margin-bottom: 30px;
        font-style: italic;
        font-size: 16px;
        font-family: $lexia;
    }

    hr {
        margin-bottom: 30px;
        border: 0;
        border-top: 1px solid $mercury;
    }

    p {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 18px;
        font-family: $proxima;
        line-height: 2em;
    }

    img {
        margin: 20px 30px 50px;
        width: 100%;

        @media (min-width: $large) {
            width: auto;
        }

        &[style*='right'] {
            margin-right: 0;
        }

        &[style*='left'] {
            margin-left: 0;
        }
    }

    blockquote {
        position: relative;
        margin-top: 45px;
        margin-bottom: 45px;
        margin-left: 45px;
        padding-left: 27px;
        border-left: 2px solid $botticelli;
        color: $scorpion;
        font-style: italic;
        font-size: 22px;
        font-family: $lexia;
        line-height: 36px;

        &:before {
            position: absolute;
            top: 14px;
            left: -50px;
            color: $glacier;
            content: '“';
            font-style: normal;
            font-size: 56px;
            font-family: $lexiaadvert;
        }
    }

    ul,
    ol {
        margin-bottom: 40px;
        padding-left: 40px;
        list-style: disc;
        font-weight: 200;

        li {
            margin: 25px 0;
        }

        ul {
            margin-bottom: 0;
            padding-left: 60px;

            li {
                margin: -10px 0 0;

                +li {
                    margin: 15px 0 0;
                }
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;

        li {
            &:before {
                margin: 0 10px 0 -10px;
                content: counter(ol-counter);
                counter-increment: ol-counter;
                font-weight: 700;
                font-family: $proxima;
            }
        }

        ul {
            list-style: disc;

            li:before {
                content: none;
            }
        }
    }

    table,
    table.tablesaw {
        margin: 30px 0 60px 0;
        width: 100%;
        font-size: 14px;

        thead {
            font-family: $lexia;

            tr {
                background: $bluechill;
                color: #fff;
                text-align: left;
            }

            th {
                padding: 15px 18px;
                border: 2px solid $atoll;
            }
        }

        tbody {
            font-family: $proxima;

            tr {
                background: $alabaster2;

                &:nth-child(even) {
                    background: #fff;
                }
            }

            td {
                padding: 15px 18px;
                border: 2px solid $mercury;
                font-weight: 400;
            }
        }
    }
}

// .two-columns {
//   @media (min-width: $large) {
//     display: flex;

//     .col-left,
//     .col-right {
//       width: 50%;
//     }

//     .col-left {
//       margin-right: 40px;
//     }
//   }
// }