.banner {
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 0 -25px;
  max-height: 400px;
  height: auto;

  .slide {
    margin-left: -25px;
    height: 400px;
    width: calc(100% + 50px);

    @media screen and (min-width: $large) {
      height: auto;
      max-height: 100%;
    }

    &:before {
      position: absolute;
      left: -25px;
      display: block;
      width: calc(100% + 500px);
      height: 240px;
      background: linear-gradient(0deg, rgba(6, 6, 6, 0), rgba(6, 6, 6, 0.85));
      content: '';

      @media screen and (min-width: $large) {
        background: linear-gradient(0deg, rgba(6, 6, 6, 0), rgba(6, 6, 6, 1) 204px);
      }
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: -25px;
      display: block;
      width: calc(100% + 500px);
      height: calc(100% - 115px);
      background: linear-gradient(0deg, rgba(6, 6, 6, 0.9), rgba(6, 6, 6, 0));
      content: '';
      opacity: 0.7;

      @media screen and (min-width: $large) {
        background: transparent;
      }
    }

    img {
      height: 100%;
      width: auto;

      @media screen and (min-width: $large) {
        height: auto;
        width: 100%;
      }
    }

    .banner-text-container {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      @media (min-width: $large) {
        right: 50px;
        left: auto;
        margin-bottom: 14px;
      }

      &:after {
        @media (min-width: $large) {
          position: absolute;
          bottom: -11px;
          display: block;
          width: 100%;
          height: 10px;
          background: #000000;
          content: '';
        }
      }
    }

    .banner-text {
      position: relative;
      padding: 20px 55px 50px;
      width: 100%;
      background: transparent url('../images/banner-text-diag-bg.png') repeat top left;

      @media (min-width: $large) {
        left: 0;
        padding-right: 55px;
        padding-left: 120px;
        background: transparent url('../images/banner-text-bg.png') top left no-repeat;
        width: 620px;
        text-align: right;
      }

      h2 {
        margin-bottom: 15px;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 800;
        font-size: 24px;
        font-family: $proxima;
        line-height: 0.9em;
      }

      .banner-sub-text {
        margin-bottom: 20px;
        color: #ffffff;
        font-family: $lexia;
      }

      a {
        color: #93dfe5;
        letter-spacing: 3px;
        font-weight: 400;

        &:visited {
          color: #93dfe5;
        }
      }
    }
  }
}

.subpage {
  &.full-width {
    .banner {
      @media (min-width: $large) {
        left: 0;
      }
    }
  }

  &.banner-collapse {
    &.projects .banner {
      max-height: 275px;

      @media (min-width: $large) {
        max-height: 145px;
      }
    }

    .banner {
      @media (min-width: $large) {
        max-height: 145px;
      }
    }
  }

  .banner {
    overflow: hidden;
    padding: 0 25px;
    max-height: 270px;

    @media (min-width: $large) {
      max-height: 370px;
    }

    .slide {

      &:after {
        opacity: 0;

        @media (min-width: $large) {
          opacity: 0.2;
        }
      }

      img {
        height: 100%;
        width: 100%;

        @media screen and (min-width: $large) {
          height: auto;
        }
      }
    }
  }

  .page-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 40px;
    padding-left: 25px;
    width: 100%;
    height: 170px;
    background: rgba(248, 248, 248, 0.95);

    @media (min-width: $large) {
      padding-top: 65px;
      height: 225px;
    }

    h1 {
      margin: 0 auto 15px auto;
      max-width: 1064px;
      font-weight: 800;
      font-family: $lexiaadvert;

      @media (min-width: $large) {
        margin-top: 15px;
      }
    }

    .tagline {
      margin: 0;
      width: 250px;
      color: $tundora;
      text-transform: uppercase;
      letter-spacing: 1px;
      letter-spacing: 2px;
      font-weight: 200;
      font-size: 15px;
      font-family: $proxima;
      line-height: 24px;

      @media (min-width: $large) {
        margin: 0 auto;
        max-width: 1064px;
        width: auto;
      }
    }

    .diagonal-border-thick {
      background: transparent;
    }

    .diagonal-border-thick:after {
      content: none;
    }
  }
}