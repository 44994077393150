html {
  overflow-x: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0 25px;

  @media screen and (min-width: $large) {
    margin: 0;
  }

  &.nav-open {
    nav {
      position: absolute;
      overflow-y: scroll;
      width: 100%;
      height: calc(100vh - 154px);
    }
  }
}

.main {
  margin: 50px auto 90px;
  max-width: 920px;

  @media screen and (min-width: $large) {
    margin-top: 75px;
  }

  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

* {
  box-sizing: border-box;
}

.subpage {
  .page-container {
    margin: 45px auto;
    min-height: calc(100vh - 828px);

    @media (min-width: $large) {
      display: flex;
      flex-direction: row;
      margin: 75px auto 90px;
      max-width: 1064px;
    }
  }

  .main {
    margin-top: 0;
    margin-right: 111px;
    margin-bottom: 0;
    width: 100%;

    @media screen and (min-width: $large) {
      margin-right: 5%;
      padding-right: 5%;
      width: 70%;
    }
  }

  &.one-column .main {
    @media screen and (min-width: $large) {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .sidebar {
    padding-top: 30px;
    width: 100%;
    border-top: 2px solid $mercury;

    @media (min-width: $large) {
      padding-top: 0;
      padding-left: 30px;
      width: 25%;
      border-top: 0;
      border-left: 2px solid $mercury;
    }
  }

  &.wide {
    .page-container {
      margin: 4.6875rem auto 5.625rem;
      padding: 0;
    }

    .main {
      margin: 0;
      padding: 0;
      max-width: none;
      width: 100%;
    }
  }

  &.full-width {
    .page-container {
      margin: 0 auto;
      padding: 0;
      max-width: 100%;
    }

    .main {
      margin: 0;
      padding: 0;
      max-width: none;
      width: 100%;
    }
  }
}
