.logo {
  float: left;
  margin-top: 10px;
  padding-right: 20px;
  width: calc(100% - 50px);

  @media (min-width: $large) {
    margin-top: 25px;
    margin-left: 7px;
    width: 25%;
    transition: all 0.25s ease-in-out;
  }

  img {
    max-height: 55px;

    @media (min-width: $large) {
      max-width: 350px;
      max-height: 85px;
      transition: all 0.25s ease-in-out;
    }
  }
}

@media (min-width: $large) {
  .darkHeader a.logo {
    margin-top: 10px;

    img {
      max-width: 227px;
      max-height: 55px;
      transition: all 0.25s ease-in-out;
    }
  }
}

.diagonal-border {
  position: relative;
  padding-left: 30px;
  background: transparent url('../images/list-item-diagonal-bullet.png') repeat-y top left;

  &:before {
    position: absolute;
    top: -4px;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-left: 10px solid $alabaster;
    content: '';
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 14px;
    height: 40px;
    background: linear-gradient(0deg, $alabaster 10%, rgba(97, 97, 97, 0));
    content: '';

    @media screen and (max-width: $medium) {
      content: none;
    }
  }
}

.diagonal-border-thick {
  position: absolute;
  margin-top: 31px;
  margin-left: -54px;
  padding-top: 0;
  width: 20px;
  height: 100%;
  background: transparent url('../images/list-item-diagonal-bullet-thick.png') top left;

  &:before {
    position: absolute;
    top: -43px;
    left: 0;
    width: 20px;
    height: 71px;
    background: transparent url('../images/list-item-diagonal-bullet-cap-thick-dark.png') no-repeat top left;
    content: '';
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, white 10%, rgba(white, 0));
    content: '';
  }
}

ul.social-media {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;

  @media (min-width: $large) {
    margin-top: 0;
  }

  li {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
