.search table tr:first-child {
  display: none;
}

.search-input {
  @media (min-width: $large) {
    display: none;
  }

  input[type='text'] {
    padding: 25px;
    padding-right: 75px;
    width: 100vw;
    border: 0;
    background: $teal;
    color: #ffffff;
    font-size: 17px;

    &::placeholder {
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}

.search-trigger-mobile {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 75px;
  height: 69px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  line-height: 74px;
  cursor: pointer;
}

//Desktop Search Styles
@media (min-width: $large) {
  .menu nav {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    ul.ul1 {
      padding-right: 60px;
    }
  }

  .search-trigger {
    position: relative;
    display: block;

    >.toggle-search {
      position: fixed;
      top: 70px;
      right: 55px;
      z-index: 10;
      float: right;
      overflow: hidden;
      width: 35px;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 50%;
      background: $bluechill;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
      line-height: 42px;
      cursor: pointer;
      transition: border-color 0.5s ease-in-out, top 0.25s ease-in-out;
      transform: translateY(-50%);

      .fa {
        display: block;
        line-height: 31px;
        transition: all 0.5s ease-in-out;
      }
    }

    .search-content {
      position: fixed;
      top: 52px;
      right: 55px;
      z-index: 5;
      overflow: hidden;
      margin-top: 0;
      margin-right: 0;
      width: 35px;
      height: 35px;
      border-radius: 16px;
      background: transparent;
      background: rgba(9, 118, 128, 0.95);
      transition: width 0.4s 0.25s, height 0.4s 0.25s, margin 0.4s 0.25s, border-radius 0.3s 0s, top 0.25s ease-in-out;

      &.open {
        top: 52px;
        right: 65px;
        margin-top: -52px;
        margin-right: -65px;
        width: calc(100vw + 100px);
        padding-left: 100px;
        height: 100vh;
        border-radius: 0;
        transition: width 0.4s, height 0.4s, margin 0.4s, border-radius 0.3s 0.1s;

        .search-style-overlay>div {
          margin-top: 0;
          opacity: 1;
          transition: opacity 0.25s 0.4s, margin-top 0.25s 0.4s;
        }
      }
    }

    .search-style-overlay {
      margin: 0 45px;

      >div {
        margin-top: -10px;
        opacity: 0;
        transition: opacity 0.25s 0s, margin-top 0.25s 0s;
      }

      .search-input {
        position: static;
        display: inline-block;
        margin: 25px;
        margin-top: 185px;
        margin-right: 0;
        width: calc(100% - 95px);
        height: 50px;
        border: 0;
        border-bottom: 1px solid rgba(white, 0.4);
        background: transparent;
        color: #ffffff;
        font-weight: 400;
        font-size: 30px;
        font-family: $proxima;

        &::placeholder {
          color: #ffffff;
          font-weight: 400;
          font-size: 30px;
          font-family: $proxima;
        }
      }

      .fa-search {
        margin-left: -4px;
        padding: 0 0 0 10px;
        width: 50px;
        height: 47px;
        border-bottom: 1px solid rgba(white, 0.4);
        color: #ffffff;
        text-align: right;
        font-size: 30px;
        cursor: pointer;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
          height: 41px;
        }
      }
    }

    &.active-search {
      .toggle-search {
        border: 2px solid #ffffff;

        .fa-search {
          margin-top: -32px;
        }
      }
    }
  }
}

.darkHeader {
  .search-trigger {
    .toggle-search {
      top: 48px;
    }

    .search-content {
      top: 31px;
    }
  }
}