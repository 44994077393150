/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/reset';
@import 'presets/variables';
@import 'presets/mixins';
// Normalize
@import 'vendor/normalize';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';
// Layout
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';
// Components
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/ie';
@import 'components/tabs';
@import 'components/accordions';
@import 'components/forms';
@import 'components/banner';
@import 'components/image-nav';
@import 'components/search';
@import 'components/accessibility';
// Vendor
@import 'vendor/font-awesome/4.2.0/scss/font-awesome';
@import 'vendor/tablesaw';
@import 'vendor/magnific-popup';
// Extras
@import 'components/shame';
