html,
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  background: white;
}

.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a {
  color: blue;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:visited {
    color: blue;
  }
  &:hover,
  &:focus {
    color: purple;
    text-decoration: underline;
  }
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

.flex-video {
  position: relative; // margin-bottom: 0.88889rem;

overflow: hidden;
  padding-top: 1.38889rem;
  padding-bottom: 67.5%;
  height: 0;
  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.wide {
    padding-bottom: 56.34%;
  }
}

.hidden-button-text {
  display: block;
  overflow: hidden;
  height: 1px;
  font-size: 0;
}

.project-overview .featured-image img {
  &.has-blur {
    display: none;
  }
  &.no-blur {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
  }
}

.subpage.blur .project-overview .featured-image img {
  &.no-blur {
    display: none;
  }
  &.has-blur {
    display: block;
  }
}
