.menu {
  position: absolute;
  top: 85px;
  right: -100vw;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgba(77, 77, 77, 0.99);
  transition: right 0.5s ease-in-out;

  @media (min-width: $large) {
    position: relative;
    top: 10px;
    right: 0;
    display: block;
    margin-left: 25%;
    width: 75%;
    height: auto;
    background: transparent;
  }
}

.search-trigger {
  display: none;
}

.primary-nav-trigger {
  float: right;
  overflow: hidden;
  margin-right: -25px;
  padding: 17px 25px 0 26px;
  width: 75px;
  height: 100%;
  border-left: 1px solid rgba(white, 0.3);
  background-color: transparent;
  color: #ffffff;
  text-align: left;
  font-size: 20px;
  line-height: 60px;
  cursor: pointer;
  transition: 0.5s background-color ease-in-out;

  @media (min-width: $large) {
    display: none;
  }

  .fa {
    position: relative;
    top: -60px;
    left: 50%;
    transition: top 0.5s ease-in-out;
    transform: translateX(-50%);
  }
}

.nav-open {
  .header {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .primary-nav-trigger {
    background-color: $mineshaft;

    .fa {
      top: 0;
    }
  }

  .menu {
    right: 0;
  }
}

.darkHeader {
  nav {
    margin-top: 5px;
  }
}

nav {
  @media (min-width: $large) {
    margin-top: 30px;
    transition: margin-top 0.25s ease-in-out;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      color: #ffffff;
    }

    &.ul1 {
      height: 66px;

      @media (min-width: $large) {
        display: flex;
        justify-content: flex-end;
      }

      .li1 {
        position: relative;
        border-top: 2px solid $tundora2;
        cursor: pointer;

        @media (min-width: $large) {
          border: 0;
        }

        &:last-child {
          border-bottom: 2px solid $tundora2;

          @media (min-width: $large) {
            border: 0;
          }
        }

        &.has_children {
          padding-right: 75px;

          @media (min-width: $large) {
            padding-right: 0;
          }

          &:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 75px;
            height: 100%;
            border-left: 2px solid $tundora2;
            color: $dustygray;
            content: '\f067';
            text-align: center;
            font-size: 18px;
            font-family: 'FontAwesome';
            line-height: 66px;

            @media (min-width: $large) {
              border: 0;
              content: none;
            }
          }
        }

        >a {
          display: inline-block;
          width: calc(100% - 15px);
          white-space: nowrap;

          &:hover {
            text-decoration: none;
          }

          @media (min-width: $large) {
            display: inline;
            margin-left: 25px;
            padding-left: 0;
            border-bottom: 2px solid rgba(white, 0.5);
          }
        }

        &.open.has_children:after {
          content: '\f068';

          @media (min-width: $large) {
            content: none;
          }
        }

        @media (min-width: $large) {
          &:hover {
            >a {
              border-bottom-color: $teal;
            }

            ul.ul2.is_child {
              display: block;
            }
          }
        }
      }

      a {
        padding-left: 25px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 17px;
        font-family: $proximaextracon;
        line-height: 66px;
        font-weight: 900;

        @media (min-width: $large) {
          white-space: nowrap;
        }

        &:visited,
        &:hover,
        &:active,
        &:focus {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }

    &.ul2 {
      display: none;
      margin-top: -5px;
      padding-bottom: 40px;

      @media (min-width: $large) {
        position: absolute;
        left: 25px;
        margin-top: -15px;
        padding: 25px 30px;
        min-width: 245px;
        background: $tundora2;
      }

      .li2 {
        @media (min-width: $large) {
          margin-bottom: 10px;
        }

        >a {
          margin-left: 25px;
          padding-left: 0;
          border-bottom: 1px solid rgba(white, 0.25);
          text-transform: capitalize;
          font-weight: 400;
          font-size: 13px;
          font-family: $proxima;
          line-height: 24px;

          @media (min-width: $large) {
            margin: 0;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
}