.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0 25px;
  width: 100%;
  height: 85px;
  transition: background-color 0.15s ease-in-out;

  @media (min-width: $large) {
    left: 50%;
    padding: 0 55px;
    transform: translateX(-50%);
  }
}

.nav-open .header,
.header.darkHeader {
  background-color: $tundora4;
}
